import { template as template_871437ac1c75430c9aea76aa90d72fbb } from "@ember/template-compiler";
const FKLabel = template_871437ac1c75430c9aea76aa90d72fbb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
