import { template as template_82526af31a4f46d29bf0d6d2b5e97d58 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_82526af31a4f46d29bf0d6d2b5e97d58(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
